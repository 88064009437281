/* eslint-disable */
/**
 * PopupHandler class is used to manage popup components (PDP).
 */
export class PopupHandler {
  constructor(triggerClass, wrapperClass, isReviewPopup = false) {
    this.triggerClass = triggerClass;
    this.wrapperClass = wrapperClass;
    this.isReviewPopup = isReviewPopup;
    this.init();
  }

  init() {
    const $openPopup = $(`.${this.triggerClass}`);
    const $panelWrapper = $(`.${this.wrapperClass}`);
    const $closePopup = $panelWrapper.find('.panel-close-button');
    const $body = $('body');

    /**
     * Toggle the popup appearance programmatically and adjusts the scrolling behavior of the page.
     * @param {string} [mode='open'] - The mode indicating whether to open or close the popup.
     *  - 'open' (default): Opens the popup by adding the 'panel-wrapper--opened' class and hides overflow.
     *  - 'close': Closes the popup by removing the 'panel-wrapper--opened' class and restores overflow.
     */
    const togglePopup = (mode = 'open') => {
      // Toggle class to open/close the popup
      $panelWrapper.toggleClass('panel-wrapper--opened', mode !== 'close');
      $body.css('overflow', mode !== 'close' ? 'hidden' : '');
    };

    // Open the popup when the trigger element is clicked
    $openPopup.on('click', event => {
      let $popupContainer = $(event.target).closest(".js-compare-popup-data");

      if($popupContainer.length == 1) {
        this.fillComparePopup($popupContainer);
      }

      event.preventDefault();
      togglePopup();
    });

    // Close the popup when the close button is clicked
    $closePopup.on('click', () => togglePopup('close'));

    // Close the popup when a click event occurs outside the popup content
    $(document).on('click', event => {
      if ($(event.target).is($panelWrapper)) {
        togglePopup('close');
      }
    });
  }

  // Checks if the popup is present on the page
  static isOnPage(triggerClass) {
    return $(`.${triggerClass}`).length > 0;
  }

  fillComparePopup(popupContainer) {
    let imgSrc = $(popupContainer).find("input[type=hidden][name=compare-img]").val();
    let title = $(popupContainer).find("input[type=hidden][name=compare-title]").val();
    let description = $(popupContainer).find("input[type=hidden][name=compare-description]").val();
    let img = $('<img>', {
        src: imgSrc,
        'data-src': imgSrc,
        class: "ls-is-cached lazyloaded",
    });

    $(".article-list-item-text .article-list-item-title").html(title);
    $(".article-list-item-text .text-content").html(description);
    $(".article-list-item .article-list-image").html(img);
  }
}

// Initialize the different popups
$(document).ready(() => {
  if (PopupHandler.isOnPage('js-guide-popup')) {
    new PopupHandler('js-guide-popup', 'guide-wrapper');
  }

  if (PopupHandler.isOnPage('js-reviews-popup')) {
    new PopupHandler('js-reviews-popup', 'reviews-wrapper', true);
  }
});