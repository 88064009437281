/**
 * Converts relative url to absolute.
 * For example /men will be returned as https://www.tena.co.uk/men
 * @param {String} href
 * @returns {String}
 */
export const convertUrlToAbsolute = href => {
  const link = document.createElement('a');
  link.href = href;

  return link.href;
};
