import { isMobile } from '../helpers/isMobile';
/**
 * Represents a class for handling header scrolling on mobile devices.
 */
export class HeaderScrollMobile {
  /**
   * Checks if the header is present on the page.
   * @returns {boolean} True if the header is present, false otherwise.
   */
  static isOnPage() {
    return $('.js-menu').length !== 0;
  }

  /**
   * Initializes a new instance of the HeaderScrollMobile class.
   */
  constructor() {
    this.$window = $(window);
    this.$header = $('#mainheader');

    this.$window.scroll(() => this.toggle());
    this.$window.resize(() => this.toggle());
  }

  /**
   * Toggles the header class based on the scroll position.
   */
  toggle() {
    if (!isMobile() && this.$window.scrollTop() > 100) {
      this.$header.addClass('header-on-scroll');
    } else if (isMobile() && this.$window.scrollTop() > 50) {
      this.$header.addClass('header-on-scroll');
    } else {
      this.$header.removeClass('header-on-scroll');
    }
  }
}
