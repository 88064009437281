import { isMobile } from './isMobile';

export const getHeaderHeight = () => {
  if (isMobile()) {
    return $('#mainheader').height();
  }
  const $menuWrapper = $('.menu-wrapper');
  if ($menuWrapper.hasClass('sticky')) {
    return $menuWrapper.height();
  }

  return $('#mainheader').height();
};
