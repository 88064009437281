import { Carousel } from './carousel';

export class CarouselProtects extends Carousel {
  /**
   * css selector of carousel container
   * @static
   */
  static selector = '.js-protects-carousel';

  /**
   * Checks if container node is present in the dom
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return document.querySelector(CarouselProtects.selector);
  }

  constructor() {
    super();

    this.carousel = new Map();
    super.init(this, CarouselProtects.selector);
  }

  /**
   * Generate config depending of the number of the slides in carousel by extending the default config
   * @param {Node} $carousel - Carousel DOM Node
   * @returns {Object} extended config
   */
  generateConfig($carousel) {
    if (super.hasSingleSlide($carousel)) {
      return super.extendConfig({
        pagination: false,
        loop: false,
        autoplay: false
      });
    }

    return super.extendConfig({
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 0,
      watchSlidesVisibility: true,
      centeredSlides: true,
      autoplay: super.toggleAutoplay($carousel),
      breakpoints: {
        768: {
          centeredSlides: false,
          slidesPerView: 1,
          spaceBetween: 0
        }
      },
      pagination: {
        el: '.js-carousel-alt-pagination',
        clickable: true,
        type: 'bullets'
      }
    });
  }
}