import { isMobile } from '../helpers/isMobile';
import { Select } from './select';

export class MobileNav {
  constructor() {
    this.isInited = false;
    this.isActive = false;
    if (isMobile() && !this.isInited) {
      this.init();
    }
    $(window).on('resize', () => {
      if ($(document.activeElement).attr('type') !== 'search') {
        this.onResize();
      }
    });
  }

  onResize() {
    if (this.isInited) {
      $('.js-mobile-header-changeLang').select2('close');
    }

    if (isMobile() && !this.isInited) {
      this.init();
    } else {
      this.toggle('deactivate');
    }
  }

  toggle(mode) {
    if (mode && mode === 'deactivate') {
      this.isActive = false;
    } else {
      this.isActive = !this.isActive;
    }
    $('body').toggleClass('mobile-nav--is-active', this.isActive);
  }

  onChangeCountry(event) {
    if (event.target.value) {
      window.location.replace(event.target.value);
    }
  }

  onMainNavItemClick(event) {
    if (isMobile()) {
      // Disable navigating away if the main nav item is clicked on the mobile.
      event.preventDefault();
    }
  }

  init() {
    this.isInited = true;

    $('.js-mobile-menu-trigger').on('click', () => this.toggle());
    $('.js-menu-item-main-link').on('click', event => this.onMainNavItemClick(event));
    $(document).on('click', '.mobile-nav--is-active .js-mobile-backdrop',
      () => this.toggle('deactivate'));

    const $mobileChangeCountry = $('.js-mobile-header-changeLang');
    $mobileChangeCountry.select2(Select.getSelectConfig($mobileChangeCountry));
    $mobileChangeCountry.on('change', event => this.onChangeCountry(event));

    const $mobileChangeCountryFooter = $('.js-mobile-footer-changeLang');
    $mobileChangeCountryFooter.select2(Select.getSelectConfig($mobileChangeCountryFooter));
    $mobileChangeCountryFooter.on('change', event => this.onChangeCountry(event));
  }
}
