/**
 * Used on: Family, products and filter pages.
 */
export class ProductsViewOptions {
  static isOnPage() {
    return $('.js-view-options').length !== 0;
  }

  constructor() {
    this.addEventListeners();
  }

  /**
   * @param {Event} event - Clicked icon element.
   */
  toggleView = (event) => {
    const $optionsGroups = $('.js-view-options-group');
    const $productCard = $('.component-product-box');
    const selectedView = $(event.currentTarget).data('view-option');

    $optionsGroups
      .toggleClass('view-options-group--active-grid', selectedView === 'grid')
      .toggleClass('view-options-group--active-list', selectedView === 'list');

    $productCard
      .toggleClass('component-product-box--four-elements', selectedView === 'grid')
      .toggleClass('component-product-box--list', selectedView === 'list');
  }

  /**
   * Listener for click on product view options icon.
   */
  addEventListeners() {
    $('.js-products-view-option').on('click', (event) => this.toggleView(event));
  }
}
