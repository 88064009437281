/* eslint-disable camelcase */
import { eventKeyCodes } from '../helpers/eventKeyCodes';
import { pushToDataLayer } from '../analytics/pushToDataLayer';
import { convertUrlToAbsolute } from '../helpers/convertUrlToAbsolute';
import { isMobile } from '../helpers/isMobile';

export class SiteSearch {
  constructor() {
    this.searchTerm = '';
    this.searchPageUrl = $('.js-site-search-url').data('search-page-url');
    this.searchQueryValue = '';
    this.searchPlaceholderLength = $('.header-top .input-site-search').attr('placeholder').length;
    this.addEvents();

    if ($('.js-input-search-main').length > 0) {
      $('.site-search-page .js-btn-site-search').attr('disabled', $('.js-input-search-main').val().length === 0);
      this.searchQueryValue = $('.js-input-search-main').val();
    }

    if (!isMobile()) {
      $('.header-top .input-site-search').attr('size', this.searchPlaceholderLength - 1);
    }
  }

  static isOnPage() {
    return document.getElementsByClassName('js-site-search-url').length > 0;
  }

  /**
   * Used to increase width of input search on desktop devices
   * Also when the focus is shifted to the input field and overlay appears over main background
   * @param {Object} event
   */
  onSearchInputFocus(event) {
    if ($(event.currentTarget).parents('.js-search-header').length > 0) {
      $('.js-header-logo').addClass('u-disableLink');
      $('.js-search-overlay').addClass('u-display-none');
      $('.js-search-header').addClass('site-search-box--is-expanded');
      if(innerWidth > 992) {
        $('.links-container-wrapper').addClass('u-display-none');
      }
      $('.js-collapse-search-header').removeClass('u-display-none');
      $('.header').addClass('header-search-expanded');

      if ($('.js-overlay-search-bg').length === 0) {
        // Add overlay when input in header is focused

        let headerHeight = $('.header-search-expanded').height();

        $('body')
          .addClass('u-overflow-hidden')
          .prepend(`<div class="overlay-search-bg js-overlay-search-bg" style="top: ${headerHeight}px;"></div>`);
      }
    }
  }

  /**
   * Used for collapse input search wrapper on desktop devices
   * Hide overlay on btn close icon
   * Reset search result and enable search button
   * @param {Object} event
   */
  onSearchDeactivateFromHeader(event) {
    this.searchTerm = '';

    if ($(event.currentTarget).hasClass('js-collapse-search-header')) {
      this.collapseSearchHeader();
    }

    $('.js-header-logo').removeClass('u-disableLink');
    $('.js-btn-site-search').attr('disabled');
  }

  /**
   * Navigate to searchPageUrl without params to reset the search
   */
   onSearchDeactivate() {
    window.location.href = this.searchPageUrl;
    this.resetInputField(false);
  }

  /**
   * Add value to search input variable
   * Added keyboard events to redirect/close input field
   * @param {Object} event
   */
  onSearchTermChange(event) {
    this.searchTerm = event.target.value;

    $(event.currentTarget.classList.contains('js-input-search-header')
      ? '.js-reset-search-header'
      : '.js-reset-search-main'
    ).toggleClass('u-display-none', this.searchTerm.length === 0);

    // Redirect action on pressed enter key on keyboard
    if (event.which === eventKeyCodes.enter) {
      this.onSearch();
    }

    // Close search input field on pressed escape key on keyboard
    if (event.which === eventKeyCodes.escape) {
      this.searchTerm = '';

      // Close overlay-background on pressed escape key on keyboard
      if ($(event.target).siblings('.js-collapse-search-header').length > 0) {
        $('.js-input-search-header').val('');
        $('.js-collapse-search-header').addClass('u-display-none');
        $('.js-search-overlay').removeClass('u-display-none');
        $('.js-search-header').removeClass('site-search-box--is-expanded');
        $('.links-container-wrapper').removeClass('u-display-none');
        $('.js-overlay-search-bg').remove();
        $('.js-input-site-search').blur();
        $('.header').removeClass('header-search-expanded');
      }
    }

    $('.js-btn-site-search').attr('disabled', this.searchTerm.length === 0);
  }

  /**
   * Redirect action on pressed enter key on keyboard
   */
  onSearch() {
    window.location.href = `${this.searchPageUrl}?q=${this.searchTerm}`;
  }

  /**
   * Navigate to new page using href passed from value of an option element
   * @param {Object} event
   */
   onMobileTabChange(event) {
    window.location.href = $(event.currentTarget).val();
  }

  /**
   * A method for resetting individual search input fields,
   * depending on the parameters that determine which search input field will be reset
   * @param {Boolean} isHeader
   */
  resetInputField(isHeader) {
    this.searchTerm = '';

    $(isHeader ? '.js-input-search-header' : '.js-input-search-main').val('');
    $(isHeader ? '.js-reset-search-header' : '.js-reset-search-main').addClass('u-display-none');
    $(isHeader ? '.js-search-header .js-btn-site-search' : '.js-site-search-page .js-btn-site-search')
      .attr('disabled', this.searchTerm.length === 0);
  }

  /**
   *  Closing search and adding overlay background to body
   */
  collapseSearchHeader() {
    this.searchTerm = '';
    $('.js-input-search-header').val('');
    $('.js-collapse-search-header').addClass('u-display-none');
    $('.js-reset-search-header').addClass('u-display-none');
    $('.js-search-overlay').removeClass('u-display-none');
    $('.js-search-header').removeClass('site-search-box--is-expanded');
    $('.links-container-wrapper').removeClass('u-display-none');
    $('.js-overlay-search-bg').remove();
    $('body').removeClass('u-overflow-hidden');
    $('.js-btn-site-search').attr('disabled', this.searchTerm.length === 0);
    $('.header').removeClass('header-search-expanded');
  }

  /**
   * Hide search on when is clicked anywhere outside the element
   * @param {Object} event
   */
  clickOutsideSearchElement(event) {
    if (!$(event.target).closest('.header').length) {
      this.collapseSearchHeader();
    }
  }

  /**
   * When the user navigates away the search by using the tab key collapse the search header and
   * focus the next item in the header manually to fix skipping .header-items links.
   */
  onFocusOutCollapseBtn() {
    this.collapseSearchHeader();
    const $nextHeaderItemToFocus = $('.header-items a');

    if ($nextHeaderItemToFocus.length > 0) {
      $($nextHeaderItemToFocus[0]).focus();
    }
  }

  onSearchDeactivateKeyup(event) {
    if (event.which === eventKeyCodes.enter) {
      this.onSearchDeactivateFromHeader(event);
    }
  }

  /**
   * Change previous focusable event going back from search input from logo
   * to change site dropdown link.
   */
  onSearchInputFocusOut() {
    const $changeSite = $('.ChangeSite-container .js-dropdown-link');

    if ($changeSite.length) {
      // Timeout used to wait for the default element to be focused first.
      setTimeout(() => $changeSite.focus(), 0);
    }
  }

  /**
   * Calculate total number of search results.
   * @returns {Number}
   */
  calculateTotalSearchResults() {
    return $('.search-result-filter-item-text').toArray().reduce((total, element) => {
      // Extract the numeric characters from the current element.
      const match = $(element).text().match(/\d+/);

      return match ? total + parseInt(match[0], 10) : total;
    }, 0);
  }

  /**
   * Related to tracking google analytics.
   * Tracks the value that was searched and total number of search results.
   * @param {String} searchTerm
   * @param {Number} totalSearchResults
   */
  searchEvents() {
    const searchTerm = $('.js-input-search-main').val();
    const totalSearchResults = this.calculateTotalSearchResults();

    if (searchTerm) {
      pushToDataLayer({
        event: 'ga4_view_search_results',
        ga4_data: {
          search_term: searchTerm,
          search_results_count: totalSearchResults
        }
      });
    }
  }

  /**
   * Related to tracking google analytics.
   * Tracks the total number of search results, and the value that was searched,
   * the text of the clicked element and the URL of the link the user clicked on.
   * @param {Object} event
   * @param {String} searchTerm
   * @param {Number} totalSearchResults
   */
  clickOnSearchResultItem(event, searchTerm, totalSearchResults) {
    const $target = $(event.currentTarget);
    const targetUrl = convertUrlToAbsolute(event.currentTarget.href);
    let clickedText;

    if ($(event.target).is('img')) {
      clickedText = $(event.target).attr('title').trim();
    } else if ($target.hasClass('ga4-search-result')) {
      clickedText = $target.find('.search-article-title').text().trim();
    } else {
      clickedText = $target.text().trim();
    }

    pushToDataLayer({
      event: 'ga4_select_search_results',
      ga4_data: {
        search_term: searchTerm,
        search_results_count: totalSearchResults,
        link_url: targetUrl,
        link_text: clickedText
      }
    });
  }

  addEvents() {
    $('.js-btn-site-search').on('click', () => this.onSearch());

    $('.js-input-site-search').on('keyup', event => this.onSearchTermChange(event));

    $('.js-input-site-search').on('focus', event => this.onSearchInputFocus(event));

    $('.js-input-site-search').on('blur', () => this.onSearchInputFocusOut());

    $('.js-collapse-search-header').on('click', event => this.onSearchDeactivateFromHeader(event));

    $('.js-collapse-search-header').on('keyup', event => this.onSearchDeactivateKeyup(event));

    $('.js-collapse-search-header').on('focusout', () => this.onFocusOutCollapseBtn());

    $('.js-reset-search-main').on('click', () => this.onSearchDeactivate());

    $('.js-reset-search-header').on('click',  () => this.resetInputField(true));

    $(document).on('click', event => this.clickOutsideSearchElement(event));

    $('.js-header-logo').on('focus', () => this.collapseSearchHeader());

    $('.js-search-result-filter-dropdown').on('change', event => this.onMobileTabChange(event));

    if ($('.js-input-search-main').length) { this.searchEvents(); }

    $('.search-page-content').on('click', '.ga-product-click, .ga-buy-now-button, .ga4-search-result', event => {
      this.clickOnSearchResultItem(event, $('.js-input-search-main').val(), this.calculateTotalSearchResults());
    });
  }
}
