/**
 * Represents a HeadingHandler class that handles the visibility
 * of product titles headings based on the window width.
 */
export class HeadingHandler {
  static isOnPage() {
    return $('.js-product-title').length !== 0;
  }

  constructor() {
    this.productTitleDesktopHTML = $('.js-product-title--desktop').prop('outerHTML');
    this.productTitleMobileHTML = $('.js-product-title--mobile').prop('outerHTML');
    this.addEventListeners();
    this.toggleHeading(); // Initial call to toggleHeading to set the correct heading
  }

  /**
   * Toggles the visibility of product titles headings based on the window width.
   */
  toggleHeading = () => {
    const $window = $(window);
    const $titleDesktop = $('.js-product-title--desktop');
    const $titleMobile = $('.js-product-title--mobile');

    if ($titleDesktop.length) {
      $titleDesktop.remove();
    }

    if ($titleMobile.length) {
      $titleMobile.remove();
    }

    if ($window.width() < 992) {
      if (!$('.js-product-title--mobile').length) {
        $('.js-product-title-flag--mobile').after(this.productTitleMobileHTML);
      }
    } else if (!$('.js-product-title--desktop').length) {
      $('.js-product-title-flag--desktop').after(this.productTitleDesktopHTML);
    }
  }

  /**
   * Call toggleHeading on window resize.
   */
  addEventListeners() {
    $(window).on('resize', this.toggleHeading);
  }
}
