import { isMobile } from '../helpers/isMobile';

export class StickyMenu {
  static isOnPage() {
    return $('.js-menu').length !== 0;
  }

  constructor() {
    this.$window = $(window);
    this.$headerMenu = $('.header-menu');
    this.$menuWrapper = this.$headerMenu.find('.menu-wrapper');
    this.$submenu = $('.submenu-header-menu.submenu-opened');

    if (this.$menuWrapper.length > 0) {
      this.toggle();
    }

    this.$window.scroll(() => this.toggle());
    this.$window.resize(() => this.toggle());
  }

  deactivate() {
    this.$menuWrapper.removeClass('sticky');
    this.$headerMenu.height('auto');
    this.$submenu.css({
      position: 'absolute',
      top: this.$menuWrapper.position().top + this.$menuWrapper.height()
    });
  }

  toggle() {
    if (isMobile()) {
      if (this.$menuWrapper.is('.sticky')) {
        return this.deactivate();
      }

      return;
    }

    if (!this.$menuWrapper || !this.$menuWrapper.data() || !this.$menuWrapper.data().defaultOffset) {
      this.$menuWrapper.data('default-offset', this.$menuWrapper.offset().top);
    }
    if (this.$menuWrapper.is('.sticky')) {
      if (this.$window.scrollTop() < this.$menuWrapper.data().defaultOffset - 0) {
        this.deactivate()
      }
    } else if (this.$window.scrollTop() >= this.$menuWrapper.data().defaultOffset - 0) {
      this.$menuWrapper.addClass('sticky');
      this.$headerMenu.height(this.$menuWrapper.height());
      if (this.$submenu.length) {
        this.$submenu.css({
          position: 'fixed',
          top: this.$menuWrapper.height()
        });
      }
    }
  }
}
