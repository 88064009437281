export class Select {
  static getSelectConfig($select) {
    const config = { dir: document.dir };
    if (!$select.hasClass('js-select-show-search')) {
      config.minimumResultsForSearch = 'Infinity';
    }

    if ($select.hasClass('js-mobile-header-changeLang')) {
      config.dropdownParent = '.header-menu-container';
    }

    if ($select.hasClass('js-mobile-footer-changeLang')) {
      config.dropdownParent = '.footer-frost';
    }

    return config;
  }

  static isOnPage() {
    return $('.js-select').length !== 0;
  }

  constructor() {
    window.onload = $(() => {
      $.each($('.js-select'), (index, select) => {
        const $select = $(select);

        if (!$select.hasClass('js-select-init-disable')) {
          $select.select2(Select.getSelectConfig($select));
        }
      });
    });
  }
}
