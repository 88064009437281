import { scrollToElement } from '../helpers/scrollToElement';

/**
 * Handling the click on a sire-achor that leads to an element
 */
export class ClickToScrollToElement {
  /**
   * Checking if the element is on the page
   * @returns {boolean}
   */
  static isOnPage() {
    return $('.js-clicked-scroll-to-element').length !== 0;
  }

  /**
   * Finds the side-anchor and add the scroll logic with some added padding above
   */
  constructor() {
    $('.js-clicked-scroll-to-element').on('click', (event) => {
      event.preventDefault();
      var $el = $($(event.currentTarget).attr('href'));
      if ($el.length) {
        scrollToElement($el);
      }
    });
  }
}