import { Carousel } from './carousel';

export class CarouselAlt extends Carousel {
  /**
   * css selector of carousel container
   * @static
   */
  static selector = '.js-carousel-alt';

  /**
   * Checks if container node is present in the dom
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return document.querySelector(CarouselAlt.selector);
  }

  constructor() {
    super();

    this.carousel = new Map();
    super.init(this, CarouselAlt.selector);
  }

  /**
   * Generate config depending of the number of the slides in carousel by extending the default config
   * @param {Node} $carousel - Carousel DOM Node
   * @returns {Object} extended config
   */
  generateConfig($carousel) {
    if (super.hasSingleSlide($carousel)) {
      return super.extendConfig({
        pagination: false,
        loop: false,
        autoplay: false
      });
    }

    return super.extendConfig({
      loop: true,
      autoplay: super.toggleAutoplay($carousel),
      pagination: {
        el: '.js-carousel-alt-pagination',
        clickable: true,
        type: 'bullets'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });
  }
}