import { analyticsAccordionItemOpen } from '../analytics/listeners/externallyTriggeredEvents';
import { isInView } from '../helpers/isInView';
import { scrollToElement } from '../helpers/scrollToElement';
import { eventKeyCodes } from '../helpers/eventKeyCodes';

export class Accordion {
  static isOnPage() {
    return $('.js-accordion').length !== 0;
  }

  constructor() {
    this.init();
    $('.js-accordion-content').hide();
  }

  activateArrow($arrow) {
    $arrow
      .removeClass('icon-arrow-down')
      .addClass('icon-arrow-up');
  }

  deactivateAllArrows($accordion) {
    $accordion.find('.js-accordion-arrow')
      .removeClass('icon-arrow-up')
      .addClass('icon-arrow-down');
  }

  activateItem(item) {
    const $item = $(item).parents('.js-accordion-item');
    this.deactivateAll($item.parents('.js-accordion'));
    $item
      .addClass('accordion-expanded')
      .removeClass('accordion-collapsed');
    this.activateArrow($item.find('.js-accordion-arrow'));
    $item.find('.js-accordion-content').slideDown(() => {
      if (!isInView(item)) {
        scrollToElement($item);
      }
		});
		analyticsAccordionItemOpen($.trim($item.find('.js-accordion-toggle').text()));
  }

  deactivateAll($accordion) {
    $accordion.find('.js-accordion-item')
      .removeClass('accordion-expanded')
      .addClass('accordion-collapsed');
    this.deactivateAllArrows($accordion);
    $accordion.find('.js-accordion-content').slideUp();
  }

  onToggle(target) {
    if ($(target).parents('.js-accordion-item').hasClass('accordion-collapsed')) {
      this.activateItem(target);
    } else {
      this.deactivateAll($(target).parents('.js-accordion'));
    }
  }

  addEvents() {
    $('.js-accordion-toggle').on('click keydown', (event) => {
      if (event.type === 'click' || event.keyCode === eventKeyCodes.enter) {
        this.onToggle(event.currentTarget);
      }
    });
  }

  init() {
    this.addEvents();
  }
}