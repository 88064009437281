export const selectMultiLevelConfig = {
  dir: document.dir,
  minimumResultsForSearch: 'Infinity',
  templateResult: data => {
    if (!data.element) {
      return data.text;
    }
    const $wrapper = $('<span></span>');
    $wrapper.addClass($(data.element)[0].className);
    $wrapper.text(data.text);

    return $wrapper;
  }
};

/**
 * Inits Select2 plugin for dropdowns that have 3 levels of options
 */
export class SelectMultiLevel {
  static isOnPage() {
    return $('.js-select-multilevel').length !== 0;
  }

  constructor() {
    $(() => {
      $.each($('.js-select-multilevel'), (index, select) =>
        $(select).select2(selectMultiLevelConfig));
    });
  }
}
