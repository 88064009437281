const selector = '.js-tabs';

export class Tabs {
  static isOnPage() {
    return $(selector).length !== 0;
  }

  constructor() {
    $(selector).find('.js-tab').click(event => this.onTabClick(event));
  }

  highlightActiveTab(index, $tab) {
    $tab.parents(selector).find('.js-tab')
      .removeClass('active')
      .eq(index).addClass('active');
  }

  activateActiveTab(index, $tab) {
    $tab.parents(selector).find('.js-tab-content')
      .removeClass('active')
      .eq(index).addClass('active');
  }

  onTabClick(event) {
    event.preventDefault();
    const $tab = $(event.currentTarget);
    const index = $tab.index();
    this.highlightActiveTab(index, $tab);
    this.activateActiveTab(index, $tab);
  }
}
