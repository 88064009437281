export class IframePopup {
  static isOnPage() {
    return $('.iframe-popup').length !== 0;
  }

  constructor() {
    this.addIframeParamSuffix();
    this.addEventListeners();
  }

  addIframeParamSuffix() {
    $('.iframe-popup').each((index, element) => {
      const $element = $(element);
      $element.attr('href', `${$element.attr('href')}?iframe=yes`);
    });
  }

  addEventListeners() {
    $('.iframe-popup').magnificPopup({
      mainClass: 'iframe-popup-large',
      type: 'iframe',
      iframe: {
        patterns: {
          azure: {
            index: 'azure.com',
            id: () => 0,
            src: 'http://www.azure.com/embed/%id%'
          }
        }
      }
    });
  }
}
