import { Carousel } from './carousel';

export class ProductSupportingCarouselJobst extends Carousel {
  /**
   * css selector of carousel container
   * @static
   */
  static selector = '.js-product-supporting-carousel-jobst';

  /**
   * Checks if container node is present in the dom
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return document.querySelector(ProductSupportingCarouselJobst.selector);
  }

  constructor() {
    super();

    this.carousel = new Map();
    super.init(this, ProductSupportingCarouselJobst.selector);
  }

  /**
   * Generate config depending of the number of the slides in carousel by extending the default config
   * @param {Node} $carousel - Carousel DOM Node
   * @returns {Object} extended config
   */
  generateConfig($carousel) {
    if (super.hasSingleSlide($carousel)) {
      return super.extendConfig({
        pagination: false,
        loop: false,
        autoplay: false,
      });
    }

    return super.extendConfig({
      slidesPerView: 2,
      loop: false,
      spaceBetween: 15,
      pagination: {
        el: '.js-carousel-pagination-supporting-jobst',
        clickable: true,
        type: 'bullets'
      },
      breakpoints: {
        992: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
      }
    });
  }
}