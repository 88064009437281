import { convertUrlToAbsolute } from '../helpers/convertUrlToAbsolute';

/**
 * Represents a class for handling external links in the mega menu and if it is an external link, it will add SVG to it.
 */
export class ExternalLinksMegaMenu {
  /**
   * Checks if the submenu is present on the page.
   * @returns {boolean}
   */
  static isOnPage() {
    return $('.js-external-links').length !== 0;
  }

  constructor() {
    this.init();
  }

  init() {
    const $dropdownContent = $('.js-external-links');
    const links = $dropdownContent.find('a');
    // Remove 'www.' prefix from the hostname
    const locationHostname = window.location.hostname.replace(/^www\./, '');

    const elementContent = `
      <svg class="svg-external-link" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
        d="M13.333 1C13.333 0.585786 13.6688 0.25 14.083 0.25H19.333C19.7472
        0.25 20.083 0.585786 20.083 1V6.25C20.083 6.66421 19.7472 7 19.333 7C18.9188
        7 18.583 6.66421 18.583 6.25V2.81066L6.36334 15.0303C6.07044 15.3232 5.59557
        15.3232 5.30268 15.0303C5.00978 14.7374 5.00978 14.2626 5.30268 13.9697L17.5223
        1.75H14.083C13.6688 1.75 13.333 1.41421 13.333 1ZM1.46169 4.12868C2.0243 3.56607
        2.78736 3.25 3.58301 3.25H11.833C12.2472 3.25 12.583 3.58579 12.583 4C12.583
        4.41421 12.2472 4.75 11.833 4.75H3.58301C3.18518 4.75 2.80365 4.90804 2.52235
        5.18934C2.24104 5.47064 2.08301 5.85218 2.08301 6.25V16.75C2.08301 17.1478
        2.24104 17.5294 2.52235 17.8107C2.80365 18.092 3.18518 18.25 3.58301 18.25H14.083C14.4808
        18.25 14.8624 18.092 15.1437 17.8107C15.425 17.5294 15.583 17.1478 15.583 16.75V8.5C15.583
        8.08579 15.9188 7.75 16.333 7.75C16.7472 7.75 17.083 8.08579 17.083 8.5V16.75C17.083 17.5457
        16.7669 18.3087 16.2043 18.8713C15.6417 19.4339 14.8787 19.75 14.083 19.75H3.58301C2.78736
        19.75 2.0243 19.4339 1.46169 18.8713C0.899078 18.3087 0.583008 17.5456 0.583008
        16.75V6.25C0.583008 5.45435 0.899078 4.69129 1.46169 4.12868Z" fill="#D92912"/>
      </svg>
    `;

    links.each((index, element) => {
      const $element = $(element);

      if ($element.find('.svg-external-link').length > 0) {
        return;
      }

      let targetURL = convertUrlToAbsolute($element.attr('href'));
      const linkUrlObject = new URL(targetURL);
      const linkUrlHostname = linkUrlObject.hostname.replace(/^www\./, '');
      const outbound = locationHostname !== linkUrlHostname;

      if (outbound) {
        $element.addClass('u-hide-after');
        $element.append(elementContent);
      }
    });
  }
}
