export class ProductComparePopup {
  /**
   * CSS selector of Compare Popup element.
   * @static
   */
  static selector = '.js-product-compare-popup';

  /**
   * Checks if element is present in the DOM.
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return $(ProductComparePopup.selector).length > 0;
  }

  /**
   * Initializes the compare popup functionality.
   * @function init
   * @static
   */
  static init() {
    const $comparePopup = $(ProductComparePopup.selector);
    const $compareCheckboxes = $('.product-card-compare-input');

    $compareCheckboxes.on('change', function () {
      const $productCard = $(this).closest('.component-product-box');
      const productName = $productCard.find('.title').text().trim();
      const productImg = $productCard.find('.product-box-img img').data('src');
      let productId = $productCard.attr('id');

      if (!productId) {
        productId = $productCard.find('.product-box-text a').data('productid');
      }

      if ($(this).is(':checked')) {
        ProductComparePopup.addProductToComparePopup($comparePopup, productId, productName, productImg);
      } else {
        ProductComparePopup.removeProductFromComparePopup($comparePopup, productId, productName);
      }

      ProductComparePopup.updatePopupVisibility($comparePopup);
      ProductComparePopup.updateCompareLink($comparePopup);
      ProductComparePopup.updateCheckboxStates();
    });

    $comparePopup.find('a').eq(0).on('click', function (e) {
      e.preventDefault();
      ProductComparePopup.clearAllSelections($comparePopup);
    });

    ProductComparePopup.clearAllSelections($comparePopup);
    ProductComparePopup.updatePopupVisibility($comparePopup);
    ProductComparePopup.updateCheckboxStates();
  }

  /**
   * Adds a product to the compare popup.
   * @function addProductToComparePopup
   * @static
   * @param {jQuery} $comparePopup - The compare popup element.
   * @param {String} id - The product ID.
   * @param {String} name - The product name.
   * @param {String} imgSrc - The product image source.
   */
  static addProductToComparePopup($comparePopup, id, name, imgSrc) {
    const $selectedProductBoxes = $comparePopup.find('.product-compare-popup--selected-product');

    $selectedProductBoxes.each(function () {
      const $productBox = $(this).closest('.product-compare-popup-product-box');
      const $productNameElem = $productBox.find('.product-compare-popup--product-name');
      const $productImgElem = $productBox.find('.product-compare-popup--product-img');
      const $unselectedText = $productBox.find('.product-compare-popup--unselected-text');

      if ($productNameElem.text() === '') {
        $productBox.attr('data-product-id', id);
        $productNameElem.text(name);
        $productImgElem.attr('src', imgSrc);
        $unselectedText.hide();
        $productBox.addClass('product-compare-popup-product-box--active');
        $productBox.find('.product-compare-popup--selected-product').show();

        return false;
      }
    });
  }

  /**
   * Removes a product from the compare popup.
   * @function removeProductFromComparePopup
   * @static
   * @param {jQuery} $comparePopup - The compare popup element.
   * @param {String} id - The product ID.
   */
  static removeProductFromComparePopup($comparePopup, id) {
    const $selectedProductBoxes = $comparePopup.find('.product-compare-popup--selected-product');

    $selectedProductBoxes.each(function () {
      const $productBox = $(this).closest('.product-compare-popup-product-box');
      const $productNameElem = $productBox.find('.product-compare-popup--product-name');
      const $productImgElem = $productBox.find('.product-compare-popup--product-img');
      const $unselectedText = $productBox.find('.product-compare-popup--unselected-text');

      // eslint-disable-next-line eqeqeq
      if ($productBox.attr('data-product-id') == id) {
        $productBox.removeAttr('data-product-id');
        $productNameElem.text('');
        $productImgElem.attr('src', '');
        $productBox.find('.product-compare-popup--selected-product').hide();
        $unselectedText.show();
        $productBox.removeClass('product-compare-popup-product-box--active');

        return false;
      }
    });
  }

  /**
   * Updates the visibility of the compare popup.
   * @function updatePopupVisibility
   * @static
   * @param {jQuery} $comparePopup - The compare popup element.
   */
  static updatePopupVisibility($comparePopup) {
    const isAnyProductSelected = $comparePopup.find('.product-compare-popup--product-name').filter(function () {
      return $(this).text() !== '';
    }).length > 0;

    if (isAnyProductSelected) {
      $comparePopup.fadeIn(300);
    } else {
      $comparePopup.fadeOut(300);
    }
  }

  /**
   * Updates the compare link with selected product IDs.
   * @function updateCompareLink
   * @static
   * @param {jQuery} $comparePopup - The compare popup element.
   */
  static updateCompareLink($comparePopup) {
    const $compareLink = $comparePopup.find('a').eq(1);
    const selectedProductIds = $comparePopup.find('.product-compare-popup--selected-product').map(function () {
      return $(this).closest('.product-compare-popup-product-box').attr('data-product-id');
    }).get().filter(Boolean);

    const currentUrl = new URL($compareLink.attr('href'));
    currentUrl.searchParams.set('selectedProducts', selectedProductIds.join(','));
    $compareLink.attr('href', currentUrl.toString());
  }

  /**
   * Clears all selections in the compare popup.
   * @function clearAllSelections
   * @static
   * @param {jQuery} $comparePopup - The compare popup element.
   */
  static clearAllSelections($comparePopup) {
    const $compareCheckboxes = $('.product-card-compare-input');
    $compareCheckboxes.prop('checked', false);

    $comparePopup.find('.product-compare-popup--selected-product').each(function () {
      const $productBox = $(this).closest('.product-compare-popup-product-box');
      const $productNameElem = $productBox.find('.product-compare-popup--product-name');
      const $productImgElem = $productBox.find('.product-compare-popup--product-img');
      const $unselectedText = $productBox.find('.product-compare-popup--unselected-text');

      $productBox.removeAttr('data-product-id');
      $productNameElem.text('');
      $productImgElem.attr('src', '');
      $(this).hide();
      $unselectedText.show();
      $productBox.removeClass('product-compare-popup-product-box--active');
    });

    ProductComparePopup.updatePopupVisibility($comparePopup);
    ProductComparePopup.updateCompareLink($comparePopup);
    ProductComparePopup.updateCheckboxStates();
  }

  /**
   * Updates the states of checkboxes based on the selected products.
   * @function updateCheckboxStates
   * @static
   */
  static updateCheckboxStates() {
    const windowWidth = $(window).width();
    let maxProducts;

    if (windowWidth <= 768) {
        maxProducts = 2;
    } else if (windowWidth <= 1200) {
        maxProducts = 3;
    } else {
        maxProducts = 4;
    }

    const selectedProductCount = $('.product-compare-popup--selected-product:visible').length;
    const $compareCheckboxes = $('.product-card-compare-input');

    $compareCheckboxes.prop('disabled', false);

    if (selectedProductCount >= maxProducts) {
      $compareCheckboxes.not(':checked').prop('disabled', true);
    }
  }
}

// Initialize the functionality
$(document).ready(function () {
  if (ProductComparePopup.isOnPage()) {
    ProductComparePopup.init();
  }
});
