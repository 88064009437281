import { isMobile } from '../helpers/isMobile';

export class Table {
  static isOnPage() {
    return $('.js-table').length !== 0;
  }

  constructor() {
    window.onload = $(() => {
      this.init();
    });
  }

  /**
   * Sets data-label attribute for each table cell,
   * required for the needs of the responsive table on mobile.
   */
  setTableColumnDataLabels() {
    $.each($('.js-table'), (index, table) => {
      const $table = $(table);

      $.each($table.find('th'), (rowIndex, heading) => {
        $table.find(`td:nth-child(${rowIndex + 1})`)
          .attr('data-label', heading.innerText);
      });
    });
  }

  init() {
    this.setTableColumnDataLabels();
    $('.js-table-filter-btn').toggleClass('js-table-filter-apply', !isMobile());
  }
}