export class Popup {
	static isOnPage() {
		return $('.js-popup-container').length !== 0;
	}

	constructor() {
		this.init();
		this.addEventListeners();
	}

	init() {
		$('.js-popup-trigger').magnificPopup({
			closeBtnInside: true,
			type: 'inline'
		});
	}

	addEventListeners() {
		$('.js-popup-close').on('click', () => $.magnificPopup.instance.close());
	}
}
