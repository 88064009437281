import { isMobile } from '../helpers/isMobile';

export class Tooltip {
	static isOnPage() {
    return $('.js-toggle-tooltip').length !== 0;
	}

	constructor() {
    this.isOpen = false;
		this.init();
	}

  removeTooltip() {
    if (!this.isOpen) {
      return;
    }

    $(this.isOpen).trigger('focus');
    $('.tooltip').remove();
    $(this.isOpen).one('mouseenter focus', event => this.open(event));
    this.isOpen = false;
  }

  open(event) {
    if (this.isOpen) {
      return;
    }

    const glossaryElement = event.currentTarget;
    const elementPosition = this.getPosition(glossaryElement);
    const data = event.target.dataset;
    this.isOpen = glossaryElement;

    const tooltip = $(`
      <div class="tooltip" tabindex="0">
        <div class="tooltip-content-wrapper">
          <div class="tooltip-content">
            <h4 class="tooltip-title">${data.title}</h4>
            <p>${data.description}</p>
          </div>
          <div tabindex="-1">
            <div class="tooltip-arrow"></div>
            <div class="icon-validation-cross tooltip-close hide show-xs"></div>
          </div>
        </div>
      </div>
    `);
    const $tooltip = $(tooltip);
    $tooltip.css({
      left: elementPosition.left,
      top: elementPosition.top
    });

    $('body').append(tooltip);

    $tooltip.trigger('focus');
  }

	init() {
		$('.js-toggle-tooltip').on('mouseenter focus', event => {
      if (!this.isOpen) {
        this.open(event);
      }
    });

    $('.js-toggle-tooltip').on('mouseleave', event => this.removeTooltip(event.currentTarget));

    $(document).on('keydown', e => {
      if (this.isOpen && (e.key === 'Escape' || e.key === 'Tab' || (e.key === 'Tab' && e.shiftKey))) {
        this.removeTooltip();
      }
    });
	}

	getPosition (glossaryElement) {
		const offset = $(glossaryElement).offset();
		const height = $(glossaryElement).height();
		const width = $(glossaryElement).width();
		const tooltipWidth = 270;
		const windowWidth = $(window).width();
		const arrowHeight = 11;
		const position = {};

		position.left = (offset.left - (tooltipWidth / 2)) + (width / 2);
		position.top = offset.top + height + arrowHeight;

    if (isMobile()) {
      position.top = position.top - 100;
    }

		if (position.left < 10) {
			position.left = 10;
		} else if (tooltipWidth + position.left > windowWidth) {
			position.left =
				position.left + (windowWidth - (tooltipWidth + position.left));
		}

		return position;
	}
}
