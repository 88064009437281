import { getHeaderHeight } from './getHeaderHeight';

export const isInView = element => {
  const $element = $(element);
  const elementTop = $element.offset().top;
  const elementBottom = $element.offset().top + $element.outerHeight();
  const screenBottom = $(window).scrollTop() + $(window).innerHeight();
  const screenTop = $(window).scrollTop();

  return ((screenBottom + getHeaderHeight() > elementTop) && (screenTop < elementBottom));
};