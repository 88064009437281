/**
 * Manages info panel on the Product Filter page, handling its display,
 * toggling, and content organization based on user interactions.
 */
export class infoPanel {
  static isOnPage() {
    return $('.js-info-panel').length > 0;
  }

  constructor() {
    this.init();
  }

  /**
   * Toggles info panel visibility and filters
   * content based on the clicked info icon or close button.
   * @param {Event} event - Passes the element that was clicked on.
   */
  togglePanel (event) {
    let mode = 'close';
    const $targetClick = $(event?.currentTarget);
    const $infoPanelContentGroup = $('.js-info-panel-content-group');

    if (event) {
      mode = $targetClick.hasClass('info-panel-close-button') ? 'close' : 'open';
    }

    $('.js-info-panel-wrapper').toggleClass('info-panel-wrapper--opened', mode !== 'close');
    $('.js-info-panel').toggleClass('info-panel--opened', mode !== 'close');
    $infoPanelContentGroup.removeClass('info-panel-content-group--hide');

    if (mode === 'open') {
      const $selectedFilterGroup = $targetClick.data('filter-group');

      $infoPanelContentGroup.each((index, element) => {
        const $filterGroup = $(element);

        if ($filterGroup.data('filter-group') !== $selectedFilterGroup) {
          $filterGroup.addClass('info-panel-content-group--hide');
        }
      });
    }
  };

  /**
   * Initializes the info panel by setting up a click event listeners.
   */
  init() {
    // Open/close panel when the trigger element is clicked
    $('.js-info-panel-toggle').on('click', (event) => this.togglePanel(event));

    // Close the panel when a click event occurs outside the panel content
    $(document).on('click', event => {
      $(event.target).is('.js-info-panel-wrapper') && this.togglePanel();
    });
  }
}