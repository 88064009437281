import { eventKeyCodes } from './../helpers/eventKeyCodes';
import { isMobile } from './../helpers/isMobile';

/**
 * Generic implementations for dropdowns.
 * Used on many pages.
 *
 * Html example:
 * <div class="js-dropdown">
 *  <a href="#" class="js-dropdown-link">Dropdown Link</a>
 *  <div class="js-dropdown-content">Dropdown Content</div>
 * </div>
 */
export class Dropdown {
  static isOnPage() {
    return document.getElementsByName('js-dropdown');
  }

  constructor() {
    this.siteNameWidth = $('.js-site-name').width();
    this.init();
  }

  /**
   * Closes all dropdowns on page
   */
  closeAllDropdowns() {
    const $dropdown = $('.js-dropdown');
    const $dropdownLink = $dropdown.find('.js-dropdown-link');
    const $dropdownContent = $dropdown.find('.js-dropdown-content');

    $dropdown.find('.arrow').remove();
    $dropdownContent.removeClass('submenu--is-active');
    $dropdownLink.removeClass('opened');
    $dropdown.removeClass('js-dropdown--is-active menu-item--is-in-focus');
    $('.js-menu-item-main-icon').removeClass('icon-arrow-up');
  }

  onDropdownLinkFocus(event) {
    this.closeAllDropdowns();

    $(event.currentTarget).parents('.menu-item.has-submenu').addClass('menu-item--is-in-focus');
  }

  /**
   * Closes single dropdown
   * @param {jQuery} $dropdown
   * @param {jQuery} $dropdownContent
   * @param {jQuery} $dropdownLink
   */
  closeDropdown($dropdown, $dropdownContent, $dropdownLink) {
    $dropdown.find('.arrow').remove();
    $dropdownContent.removeClass('submenu--is-active');
    $dropdownLink
      .removeClass('opened')
      .find('.js-menu-item-main-icon').removeClass('icon-arrow-up');
  }

  /**
   * Shows arrow pointning from dropdown content to dropdown link
   * @param {jQuery} $dropdown
   * @param {jQuery} $dropdownLink
   */
  showArrow($dropdown, $dropdownLink) {
    let $arrow = $dropdown.find('.arrow');

    if (!$arrow.length) {
      $arrow = $('<div class="arrow"></div>');
      $dropdown.append($arrow);
    }

    $arrow.css({
      left: $dropdownLink[0].offsetLeft + ($dropdownLink.width() / 2) - ($arrow.width() / 2)
    });

    if ($dropdown.find('.js-is-show-more-dropdown').length) {
      const $dropdownContent = $dropdown.find('.js-dropdown-content');
      const $siteName = $('.js-site-name');
      const siteNameBottom = $siteName.offset().top + $siteName.find('a').height();
      const middle = $siteName.offset().left + ($siteName.width() / 2);
      let dropdownsLeft = middle - ($dropdownContent.width() / 2);

      if (this.siteNameWidth > 120 && window.innerWidth > 992) {
        dropdownsLeft = 'auto';
      }

      $arrow.offset({
        left: middle - ($arrow.width() / 2),
        top: siteNameBottom
      });

      $dropdownContent.offset({
        left: dropdownsLeft,
        top: $arrow.offset().top + $arrow.height() - 1
      });
    }
  }

  /**
   * Opens dropdown
   * @param {jQuery} $dropdown
   * @param {jQuery} $dropdownLink
   */
  openDropdown($dropdown, $dropdownLink) {
    const $dropdownContent = $dropdown.find('.js-dropdown-content');

    if ($dropdown.hasClass('js-country-selector')) {
      const width = $dropdown.parents('.container').width();
      $dropdownContent.css({ width });
    }

    $dropdown.addClass('js-dropdown--is-active');
    $dropdownContent.addClass('submenu--is-active');
    this.showArrow($dropdown, $dropdownLink);
    $dropdownLink
      .addClass('opened')
      .find('.js-menu-item-main-icon').addClass('icon-arrow-up');
  }

  /**
   * Toggles dropdown visibillity.
   * @param {jQuery} $dropdown
   * @param {jQuery} $dropdownContent
   * @param {jQuery} $dropdownLink
   */
   toggleDropdown($dropdown, $dropdownContent, $dropdownLink) {
    if ($dropdownLink.length > 1) {
      // Prevent duplicated toggle call when there are multiple dropdown links in the dropdown.
      // This will happen when a button is added in addition to link for keyboard usage.

      return;
    }

    if ($dropdownLink.hasClass('opened')) {
      this.closeDropdown($dropdown, $dropdownContent, $dropdownLink);
    } else {
      this.openDropdown($dropdown, $dropdownLink);
    }
  }

  /**
   * Opens dropdown on larger screens.
   * If Table filter - sets css 'left' property to position the dropdown in a scrollable container.
   * @param {jQuery} $dropdown
   * @param {jQuery} $dropdownLink
   */
  onDropdownHover($dropdown, $dropdownLink) {
    if (!isMobile() || !$dropdownLink.hasClass('menu-item-expand')) {
      this.openDropdown($dropdown, $dropdownLink);

      if ($dropdown.attr('tableType') === 'tableFilter') {
        // #js-table_wrapper ID generated by Swiper.js
        const tableLeftOffset = $dropdown.parents('#js-table_wrapper').offset().left;
        const dropdownLeftOffset = $dropdown.offset().left;

        $dropdown.find('.submenu').css({left: dropdownLeftOffset - tableLeftOffset});
      }
    }
  }

  /**
   * TODO: Refactor events and groups those that go on same element.
   * Example - https://learn.jquery.com/events/handling-events/#many-events-and-handlers
   *
   * Adds hover event for large screens, click for small, as well as events for keyboard navigation.
   * @param {HTMLElement} dropdown
   */
  addEventListeners(dropdown) {
    const $dropdown = $(dropdown);
    const $dropdownLink = $dropdown.find('.js-dropdown-link');
    const $dropdownContent = $dropdown.find('.js-dropdown-content');

    $dropdownLink.on('mouseover', () => this.onDropdownHover($dropdown, $dropdownLink));

    $dropdownLink.on('click', () => {
      if (isMobile() || $dropdownLink.hasClass('menu-item-expand')) {
        if ($dropdownLink.hasClass('opened')) {
          return this.closeDropdown($dropdown, $dropdown.find('.js-dropdown-content'), $dropdownLink);
        }
        this.openDropdown($dropdown, $dropdownLink);
      }
    });

    $dropdown.on('mouseleave', () => {
      const isSegmentDropdown = $dropdown.hasClass('ChangeSite-container');

      this.segmentTimeout = setTimeout(() => {
        if (!isMobile() || !$dropdownLink.hasClass('menu-item-expand')) {
          this.closeDropdown($dropdown, $dropdownContent, $dropdownLink);
        }

        if (!isMobile() && $dropdownLink.hasClass('menu-item-expand')) {
          $('body').removeClass('body-backdrop');
        }
      }, isSegmentDropdown ? 300 : 0);
    });

    // When the dropdown link is focused open the dropdown on enter, and close on escape.
    $dropdownLink.on('keyup', event => {
      const { enter, escape } = eventKeyCodes;

      if (event.which === enter) {
        this.toggleDropdown($dropdown, $dropdownContent, $dropdownLink);
      }

      if (event.which === escape) {
        this.closeDropdown($dropdown, $dropdownContent, $dropdownLink);
      }
    });

    // Used to close previously opened dropdowns when navigating to the new ones link.
    // Solves problem of closing popup when navigating away from firm with shift + tab
    $dropdownLink.on('focus', event => this.onDropdownLinkFocus(event));

    const $dropdownContentLinks = $dropdownContent.find('a');
    // Used to close the dropdown when navigating away from the last link in the dropdown.
    $dropdownContentLinks.on('focusout', event => {
      const $nextFocusableElement = $(event.originalEvent.relatedTarget);

      if ($dropdownContent.find($nextFocusableElement).length === 0) {
        this.closeDropdown($dropdown, $dropdownContent, $dropdownLink);
        $('.js-dropdown').removeClass('menu-item--is-in-focus');
      }
    });

    $dropdownContentLinks.on('keyup', event => {
      const { escape } = eventKeyCodes;
      if (event.which === escape) {
        this.closeDropdown($dropdown, $dropdownContent, $dropdownLink);
        $dropdownLink.focus();
      }
    });

    const onKeyboardFocusOut = event => {
      const $nextFocusableElement = $(event.originalEvent.relatedTarget);

      if ($dropdownContent.find($nextFocusableElement).length === 0) {
        $('.js-dropdown').removeClass('menu-item--is-in-focus');
      }
    };

    $dropdown.find('.js-menu-item-expand-keyboard')
      .on('focusout', event => onKeyboardFocusOut(event));

    $('.js-menu .js-dropdown:first-child .js-menu-item-main-link').on('focusout', event => {
      const $nextFocusableElement = $(event.originalEvent.relatedTarget);

      if (!$nextFocusableElement.parents('.js-dropdown').length) {
        $('.js-dropdown').removeClass('menu-item--is-in-focus');
      }
    });
  }

  /**
   * Fixes width of change size dropdown. Needed for active long segment name.
   */
  fixChangeSiteDropdownWidth() {
    if (this.siteNameWidth > 120 && !isMobile()) {
      $('.ChangeSite-list').css('width', this.siteNameWidth + 100);
    }
  }

  init() {
    $.each($('.js-dropdown'), (index, dropdown) => this.addEventListeners(dropdown));

    this.fixChangeSiteDropdownWidth();
  }
}
