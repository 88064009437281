/**
 * This class handles the lazy-loading of images by removing the 'lazyloaded' class
 * from images that are visible in the viewport on page load using the Intersection Observer API.
 */
export class ImageLazyload {
  /**
   * CSS selector of Image LazyLoader element.
   * @static
   */
  static selector = 'img.lazyloaded';

  /**
   * Checks if the element is present in the DOM.
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return $(ImageLazyload.selector).length > 0;
  }

  /**
   * Initializes the class by selecting all images with the 'lazyloaded' class
   * and setting up the Intersection Observer to monitor their visibility.
   */
  constructor() {
    // Select all img elements with the 'lazyloaded' class
    this.$images = $('img.lazyloaded');

    // IntersectionObserver setup to trigger when images are 10% visible
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: 0.1
    });

    this.observeImages();

    // Remove 'lazyloaded' class if present when the screen is loaded
    $(window).on('load', this.removeLazyLoadedOnLoad.bind(this));
  }

  /**
   * Callback function for Intersection Observer. It removes the 'lazyloaded' class
   * based on the visibility of images.
   *
   * @param {IntersectionObserverEntry[]} entries - Array of intersection observer entries for each image.
   */
  handleIntersect(entries) {
    entries.forEach(entry => {
      const $image = $(entry.target);

      // If the image is intersecting (visible), remove the 'lazyloaded' class
      if (entry.isIntersecting && $image.hasClass('lazyloaded')) {
        $image.removeClass('lazyloaded');
      }
    });
  }

  /**
   * Observes all images with the 'lazyloaded' class on the page
   * and applies the Intersection Observer to monitor their visibility.
   */
  observeImages() {
    this.$images.each((index, img) => {
      this.observer.observe(img);
    });
  }

  /**
   * Removes the 'lazyloaded' class from images if they are visible when the page is loaded.
   */
  removeLazyLoadedOnLoad() {
    this.$images.each((index, img) => {
      this.observer.observe(img); // Apply IntersectionObserver to monitor all images when page loads
    });
  }
}

// Initialize the ImageLazyload class when the document is ready.
$(document).ready(() => {
  new ImageLazyload();
});
