export class ShowMore {
  static isOnPage() {
    return $('.js-show-more-container').length !== 0;
  }

  constructor() {
    this.inited = false;
    this.items = [];
    this.init();
    $(window).on('resize', () => this.onResize());
  }

  onResize() {
    this.init();
  }

  onButtonClick(index, $content, $button, initialHeight) {
    $content.animate({ height: this.items[index].active ? initialHeight : this.items[index].height }, 'fast');
    $button.toggleClass('slideHide-show', !this.items[index].active);
    this.items[index].active = !this.items[index].active;
  }

  calculateHeight($element, rowsCount) {
    let paddingTop, lineHeight;
    const paddingTopValue = $element.css('padding-top');
    const lineHeightValue = $element.css('line-height');

    if (paddingTopValue) {
      paddingTop = parseInt(paddingTopValue.replace('px', ''));
    } else {
      paddingTop = 0;
    }

    if (lineHeightValue) {
      if (lineHeightValue.includes('px')) {
        lineHeight = parseFloat(lineHeightValue);
      } else {
        const fontSize = parseFloat($element.css('font-size'));
        lineHeight = parseFloat(lineHeightValue) * fontSize;
      }
    } else {
      lineHeight = 22;
    }

    return parseInt(paddingTop + (rowsCount * lineHeight));
  }

  toggle(index, container) {
    $(container).find('.js-show-more-content').height('initial');
    const $container = $(container);
    const height = $container.find('.js-show-more-content').height();
    // Default value of rows is set to 5 if data-row-count attribute is not set.
    const rowCount = $container.data('row-count') || 5;
    this.items[index] = {
      active: false,
      height
    };

    const initialHeight = this.calculateHeight($container.find('p'), rowCount);
    const $content = $container.find('.js-show-more-content');
    const $button = $container.find('.js-show-more-toggle');
    $button.removeClass('slideHide-show');
    const toggle = height > 200;

    if (toggle) {
      $content.height(initialHeight);
      $content.addClass('ContentSlide');
      $button.parents('.Button--AccordionWrap').addClass('u-display-block');
      if (!this.inited) {
        $button.on('click', () => this.onButtonClick(index, $content, $button, initialHeight));
      }
    } else {
      $content.removeClass('ContentSlide');
      $button.parents('.Button--AccordionWrap').remove();
    }
  }

  init() {
    $.each($('.js-show-more-container'), (index, container) => this.toggle(index, container));
    this.inited = true;
  }
}