import { eventKeyCodes } from '../helpers/eventKeyCodes';

export class ScrollToTop {
  static isOnPage() {
    return $('.Button--backToTop').length !== 0;
  }
  constructor() {
    this.$button = $('.Button--backToTop');
    this.visible = false;

    $(window).scroll(() => this.toggleButton());
    this.$button.click(() => this.ScrollToTop());
    this.$button.on('keydown', (e) => {
      if (e.keyCode === eventKeyCodes.enter) {
        this.ScrollToTop()
      }
    })
  }

  toggleButton() {
    if (window.scrollY >= 250 && !this.visible) {
      this.$button.fadeIn('slow');
      this.visible = true;
    }
    if (window.scrollY < 250 && this.visible) {
      this.$button.fadeOut('slow');
      this.visible = false;
    }
  }

  ScrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 400);
  }
}